import React, { useContext } from "react";
import "./App.css";

import { showTermsAndConditions, showPrivacyPolicy } from "./Privacy.js";
import { globalContext } from "./Context.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAt, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faTwitter } from "@fortawesome/free-brands-svg-icons";

const Footer = (props) => {
  let { dispatch } = useContext(globalContext);
  return (
    <footer className="footer bg-new-darker text-center text-secondary user-select-none py-2">
      <div>
        <FontAwesomeIcon icon={faEnvelope} /> info
        <FontAwesomeIcon icon={faAt} />
        insentico.com
      </div>
      <div>
        <a
          href="#/"
          className="d-inline-block text-info text-nowrap mr-2"
          onClick={(e) => {
            e.preventDefault();
            document.activeElement.blur();
            showTermsAndConditions(dispatch);
          }}
        >
          Terms &amp; Conditions
        </a>
        <a
          href="#/"
          className="d-inline-block text-info text-nowrap"
          onClick={(e) => {
            e.preventDefault();
            document.activeElement.blur();
            showPrivacyPolicy(dispatch);
          }}
        >
          Privacy Policy
        </a>
      </div>
      <div>
        Insentico Ltd. &copy; 2018-{new Date().getFullYear()} All rights
        reserved.
      </div>
    </footer>
  );
};
export default Footer;
