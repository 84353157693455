import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { fetchWithRetry, sleep } from "./utils.js";
import { Modal } from "./Modal.js";
import loading from "./assets/images/loading.gif";
async function sendMessage(
  email,
  name,
  details,
  messageTitle,
  setSuccess,
  setError,
  setIsSending,
  resetState,
  componentIsMounted
) {
  const endpoint = "https://api.insentico.com/contact-form.php";
  let params = {
    name: name,
    email: email,
    title: messageTitle,
    message: details,
  };

  try {
    await sleep(3000);
    await fetchWithRetry(
      endpoint,
      "post",
      params,
      () => {
        if (componentIsMounted.current) {
          setIsSending(false);
          resetState();
          setSuccess(true);
        }
      },
      () => {
        if (componentIsMounted.current) {
          setIsSending(false);
          setError(true);
        }
      }
    );
  } catch (e) {
    setIsSending(false);
  }
}

const ModalContact = (props) => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [details, setDetails] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const componentIsMounted = useRef(true);

  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (componentIsMounted.current && email) {
      if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
        setIsValidEmail(true);
      } else {
        setIsValidEmail(false);
      }
    }
  }, [email]);
  return (
    <Modal
      id="modalContact"
      header={props.header}
      okText="Close"
      content={
        <form>
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              className="form-control"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            {!isValidEmail && email && (
              <small id="emailHelp" className="form-text text-danger">
                *Enter valid email
              </small>
            )}
          </div>
          <div className="form-group">
            <label>Name</label>
            <input
              type="text"
              className="form-control"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </div>
          <div className="form-group">
            <label htmlFor="contactDetails">Details</label>
            <textarea
              rows="5"
              className="form-control"
              placeholder="Tell us what you need"
              value={details}
              onChange={(e) => {
                setDetails(e.target.value);
              }}
            />
          </div>
          {isSending ? (
            <div className="mt-3 text-info">
              Sending...
              <div className="loading-img-sm ml-3" title="Loading..">
                <img src={loading} alt="EasyCryptoBot " />
              </div>
            </div>
          ) : (
            <>
              <button
                id="sendBtn"
                className="btn btn-info btn-block mt-3 w-25"
                type="button"
                disabled={!isValidEmail || !name || !details}
                onClick={(e) => {
                  e.preventDefault();
                  setIsSending(true);
                  sendMessage(
                    email,
                    name,
                    details,
                    props.header === "How can we help?" ? "Info needed" : "Individual plan inquiry",
                    setSuccess,
                    setError,
                    setIsSending,
                    () => {
                      setEmail("");
                      setName("");
                      setDetails("");
                      setIsValidEmail(false);
                    },
                    componentIsMounted
                  );
                }}
              >
                Send
              </button>
              {(!email || !name || !details) && (
                <small id="emailHelp" className="form-text text-info">
                  *Fill in all fields
                </small>
              )}
            </>
          )}
          {success && (
            <div className="mt-3">
              <div className="text-info">
                <FontAwesomeIcon icon={faEnvelope} /> Thank you for contacting us!
              </div>
              <div className="small">A reply will be sent to your email.</div>
            </div>
          )}
          {error && (
            <div className="mt-3">
              <div className="text-danger">
                <FontAwesomeIcon icon={faEnvelope} /> Message failed!
              </div>
              <div className="small">
                Please try again or contact us directly at{" "}
                <span className="text-info">{props.toEmail ? props.toEmail : "info@insentico.com"}</span>
              </div>
            </div>
          )}
        </form>
      }
      func={() => {
        setEmail("");
        setName("");
        setDetails("");
        setIsValidEmail(false);
        setIsSending(false);
        setSuccess(false);
        setError(false);
      }}
    />
  );
};
export default ModalContact;
