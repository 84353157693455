import { createContext } from "react";

let initialState = {
  strategies: [],
  commissionRate: 0.075,
  btSize: { type: "base", value: "" },
  dateTo: new Date(),
  dateFrom: new Date(new Date().setMonth(new Date().getMonth() - 1)),
  confirmDialog: { header: "", content: "", func: null },
  infoDialog: { header: "", content: "" },
  contactFormDialog: { header: "", toEmail: ""},

  pairsSort: "chP",
  strategiesSort: "name",
  tradingSort: "name",
  simulationSort: "name",
  version: "2.5",
  build: "100473",
  contest: {
    month: "",
    submit: "",
    start: "",
    end: "",
    pair: "",
    prizes: [],
    executions: null,
    page: 0,
    total: 0,
    loading: true,
    lastUpdated: null,
  },
  nextContest: {
    month: "",
    submit: "",
    start: "",
    end: "",
    pair: "",
    maxStr: "",
    prizes: [],
  },
};

let globalContext = createContext(initialState);

let globalContextReducer = (state, action) => {
  switch (action.type) {
    case "contests":
      return {
        ...state,
        contest: action.payload.lastContest,
        nextContest: action.payload.next,
      };
    case "contestRanking":
      return {
        ...state,
        contest: {
          ...state.contest,
          executions: action.payload.executions,
          total: action.payload.total,
          loading: false,
        },
      };
      case "contestWinners":
      return {
        ...state,
        contest: {
          ...state.contest,
          executions: action.payload,
        },
      };
    case "strategiesSort":
      localStorage.setItem("strategiesSort", action.payload);
      return { ...state, strategiesSort: action.payload };
    case "confirmDialog":
      return { ...state, confirmDialog: action.payload };
    case "contactFormDialog":
      return { ...state, contactFormDialog: action.payload };
    case "infoDialog":
      return { ...state, infoDialog: action.payload };
    case "commissionRate":
      localStorage.setItem("commissionRate", action.payload);
      return { ...state, commissionRate: action.payload };
    case "dateFrom":
      return { ...state, dateFrom: action.payload };
    case "dateTo":
      return { ...state, dateTo: action.payload };
    case "btSize":
      return { ...state, btSize: action.payload };
    default: {
      console.log("UNKNOWN CONTEXT : " + action.type);
      return { ...state };
    }
  }
};

export { initialState, globalContext, globalContextReducer };
