import React, { useEffect, useContext } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import Navigation from "./Navigation.js";
import { globalContext } from "./Context.js";
import { GlobalContextProvider } from "./ContextProvider.js";
import { Modal, ModalConfirm } from "./Modal.js";
import Home from "./Home.js";
import ModalContact from "./ModalContact";

const ScrollToTop = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [props.path]);

  return "";
};

const Meta = (props) => {
  useEffect(() => {
    document.title = props.title;
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", props.metaContent);
  }, []);

  return "";
};

const routes = [
  { path: "/", name: "Home", Component: Home, exact: true },
  {
    path: "/contact",
    name: "Contact us",
    right: true,
    link: true,
    redirectTo: "https://insentico.com",
    exact: true,
  },
];

const MainPage = (props) => {
  let { state, dispatch } = useContext(globalContext);

  return (
    <>
      <div id="mainPage" className="">
        <Router>
          <header>
            <Navigation routes={routes} dispatch={dispatch} state={state} />
          </header>
          <main>
            <Switch>
              {routes.map(({ name, path, Component, redirectTo, exact }) =>
                !redirectTo ? (
                  <Route key={path} path={path} exact={exact}>
                    {({ match }) => (
                      <>
                        <Component />
                        <ScrollToTop path={path} />
                      </>
                    )}
                  </Route>
                ) : null
              )}
              <Redirect to="/"></Redirect>
            </Switch>
          </main>
        </Router>
      </div>
      <ModalConfirm
        id={"confirmDialog"}
        header={state.confirmDialog.header}
        content={state.confirmDialog.content}
        func={() => {
          if (state.confirmDialog.func()) {
            state.confirmDialog.func();
          }
        }}
        large={state.confirmDialog.large}
        dark={state.confirmDialog.dark}
      />
      <Modal
        id="infoDialog"
        header={state.infoDialog.header}
        content={state.infoDialog.content}
        large={state.infoDialog.large}
        dark={state.infoDialog.dark}
      />
      <ModalContact
        id={"contactFormDialog"}
        header={state.contactFormDialog.header}
        toEmail={state.contactFormDialog.toEmail}
      />
    </>
  );
};

function App() {
  return (
    <GlobalContextProvider>
      <MainPage />
    </GlobalContextProvider>
  );
}
export { Meta };
export default App;
