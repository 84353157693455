import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
//import "video-react/dist/video-react.css";
import App from "./App";
//import * as serviceWorker from "./serviceWorker";

//ReactDOM.render(<App />, document.getElementById("root"));
const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(<App />, rootElement);
} else {
  ReactDOM.render(<App />, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

/*if ("serviceWorker" in navigator) {
  serviceWorker.unregister();
} else {
  console.log("ServiceWorker Not Supported!");
}*/
