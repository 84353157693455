import React from "react";

import { showInfoDialog } from "./utils.js";



function showTermsAndConditions(dispatch) {
  import("./terms-and-conditions.js").then((component) => {
    showInfoDialog(
      dispatch,
      <span className="text-info">Terms &amp; Conditions</span>,
      component.default,
      true
    );
  });
}

function showPrivacyPolicy(dispatch) {
  import("./privacy-policy.js").then((component) => {
    showInfoDialog(
      dispatch,
      <span className="text-info">Privacy Policy</span>,
      component.default,
      true
    );
  });
}



export { showTermsAndConditions, showPrivacyPolicy };
