import React, {
  useEffect,
  lazy,
  Suspense,
  useRef,
  useState,
  useContext,
} from "react";
import logo from "./assets/images/logo.png";
import Questions from "./Questions.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartLine,
  faUsers,
  faRunning,
  faCheckCircle,
  faQuestionCircle,
  faAngleUp,
  faAngleDown,
  faCaretRight,
  faMobileAlt,
  faSitemap,
  faPeopleCarry,
  faCode,
  faRainbow,
} from "@fortawesome/free-solid-svg-icons";
import { Meta } from "./App.js";
import Footer from "./Footer";
import { showContactFormDialog } from "./utils";
import { globalContext } from "./Context";
import { faWordpress } from "@fortawesome/free-brands-svg-icons";

const HomeImages1 = lazy(() => import("./HomeImages1.js"));
const HomeImages2 = lazy(() => import("./HomeImages2.js"));
const HomeImages3 = lazy(() => import("./HomeImages3.js"));
const HomeImages4 = lazy(() => import("./HomeImages4.js"));

const ExpandableCard = (props) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className="card bg-transparent border-0 mb-4 user-select-none">
      <h4
        className={`h6 card-header rounded d-flex cursor-pointer ${props.classHeader}`}
        onClick={(e) => {
          e.preventDefault();
          document.activeElement.blur();
          setExpanded(!expanded);
        }}
      >
        <div className="flex-grow-1">{props.label}</div>
        {expanded ? (
          <FontAwesomeIcon icon={faAngleUp} />
        ) : (
          <FontAwesomeIcon icon={faAngleDown} className="blink" />
        )}
      </h4>
      <div className={expanded ? "d-block fade-in" : "d-none"}>
        <div className="card-body p-2">
          <div className="card-text text-justify">{props.toExpand}</div>
        </div>
      </div>
    </div>
  );
};

const Indicators = (props) => {
  let { dispatch } = useContext(globalContext);
  const observer = useRef(
    new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            let indicatorsLeft = document.getElementById("indicatorsLeft");
            let indicatorsRight = document.getElementById("indicatorsRight");
            let indicatorsBottom = document.getElementById("indicatorsBottom");

            indicatorsLeft.classList.add("left-slide-full");
            indicatorsRight.classList.add("right-slide-full");
            indicatorsBottom.classList.add("bottom-slide1");
            indicatorsLeft.classList.remove("d-none");
            indicatorsRight.classList.remove("d-none");
            indicatorsBottom.classList.remove("d-none");
          }
        });
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.8,
      }
    )
  );
  useEffect(() => {
    observer.current.observe(document.getElementById("indicators"));
  }, []);

  return (
    <div className="container py-5 overflow-hidden">
      <div id="indicators">
        <h2 className="text-left mb-4">
          <FontAwesomeIcon icon={faChartLine} /> Services
        </h2>
        <div className="justify-content-center row row-cols-1 row-cols-md-2 pb-md-5">
          <div className="col d-none" id="indicatorsLeft">
            <ExpandableCard
              classHeader="bg-light text-dark "
              label="Requirement Analysis"
              toExpand={
                <>
                  This involves gathering and analyzing the client's software
                  requirements, understanding their business objectives, and
                  translating them into actionable project specifications.
                </>
              }
            />
            <ExpandableCard
              classHeader="bg-light text-dark "
              label="Design and Architecture"
              toExpand={
                <>
                  We create the overall design and architecture of the
                  software, including user interface (UI) and user experience
                  (UX) design, database design, system architecture, and
                  software component design.
                </>
              }
            />
            <ExpandableCard
              classHeader="bg-light text-dark "
              label="Software Development"
              toExpand={
                <>
                  This is the core activity where we write, code, and
                  develop the software application based on the specified
                  requirements. It involves programming, database development,
                  integration of various software components, and ensuring code
                  quality and performance.
                </>
              }
            />
            <ExpandableCard
              classHeader="bg-light text-dark "
              label="Quality Assurance and Testing"
              toExpand={
                <>
                  We conduct rigorous testing to identify and fix
                  bugs, ensure functionality and reliability, and validate that
                  the software meets the desired specifications. This includes
                  unit testing, integration testing, system testing, and user
                  acceptance testing.
                </>
              }
            />
          </div>
          <div className="col d-none" id="indicatorsRight">
            <ExpandableCard
              classHeader="bg-light text-dark "
              label="Deployment and Implementation"
              toExpand={
                <>
                  After the software is developed and tested, we help
                  deploy the application to the production environment, ensuring
                  a smooth transition from development to live usage. This may
                  involve configuration, installation, data migration, and
                  training.
                </>
              }
            />
            <ExpandableCard
              classHeader="bg-light text-dark "
              label="Maintenance and Support"
              toExpand={
                <>
                  Once the software is deployed, we provides ongoing
                  maintenance and support services. This includes bug fixes,
                  software updates, performance optimization, security patches,
                  and technical support to ensure the software runs smoothly and
                  remains up-to-date.
                </>
              }
            />
            <ExpandableCard
              classHeader="bg-light text-dark "
              label="Consulting and Advisory Services"
              toExpand={
                <>
                  We offer consulting services to guide our
                  clients in technology selection, software strategy, and
                  process improvement. We provide expert advice, perform
                  technology audits, and help our clients align their software
                  solutions with their business objectives.
                </>
              }
            />
            <ExpandableCard
              classHeader="bg-light text-dark "
              label="Research and Development"
              toExpand={
                <>
                  We engage in research and development
                  activities to explore emerging technologies, experiment with
                  innovative ideas, and enhance our expertise. This enables
                  us to stay at the forefront of technological advancements
                  and offer cutting-edge solutions to our clients.
                </>
              }
            />
          </div>

          <div
            className="col card bg-transparent border-0 my-4 pb-2 d-none"
            id="indicatorsBottom"
          >
            <h4 className="h6 p-2 card-header rounded bg-light text-dark">
              <FontAwesomeIcon icon={faQuestionCircle} /> You don't see what you
              are looking for..
            </h4>
            <div className="card-body p-2">
              <a
                className="card-text text-center text-info user-select-none"
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  document.activeElement.blur();
                  showContactFormDialog(
                    dispatch,
                    "How can we help?",
                    "info@insentico.com"
                  );
                }}
              >
                Send us a quick question here!
                <FontAwesomeIcon icon={faCaretRight} className="ml-1" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const Optimization = (props) => {
  let { dispatch } = useContext(globalContext);
  return (
    <div className="container pb-5">
      <h2 className="">
        Unleash your Business Growth Through Software and Digitalization!
      </h2>
      <div className="h5 mb-3 mb-md-5">Optimize your business!</div>
      <div className="row">
        <div className="col-12 col-md-6 col-lg-5">
          <p>
            Digitalizing your business offers advantages such as increased
            efficiency, improved customer experiences, expanded market reach,
            data-driven decision making, agility, cost savings, and
            collaborative work capabilities. Embracing digital transformation is
            essential for your businesses{" "}
            <b>
              to stay competitive, drive growth, and thrive in the digital era.
            </b>
          </p>
          <a
            className="btn btn-lg btn-dark my-3 text-uppercase"
            href="/"
            onClick={(e) => {
              e.preventDefault();
              document.activeElement.blur();
              showContactFormDialog(
                dispatch,
                "How can we help?",
                "info@insentico.com"
              );
            }}
          >
            Contact us
          </a>
        </div>
        <Suspense fallback={<div></div>}>{<HomeImages3 />}</Suspense>
      </div>
    </div>
  );
};
const Backtest = (props) => {
  let { dispatch } = useContext(globalContext);
  return (
    <div className="container pt-5 pb-md-5">
      <h2 className="text-light-green">
        Empower external specialists to cater to your software needs
      </h2>
      <div className="h5 mb-3 mb-md-5">While you focus on your business</div>
      <div className="row">
        <div className="col-12 col-md-6 col-lg-5">
          <p>
            Allow our proficient team of experts to handle the outsourcing of
            your software requirements with utmost precision and skill.
          </p>
          <button
            className="btn btn-lg bg-light-green my-3 text-uppercase"
            type="button"
            id="backtestBtn"
            onClick={() => {
              document.activeElement.blur();
              showContactFormDialog(
                dispatch,
                "How can we help?",
                "info@insentico.com"
              );
            }}
          >
            Contact us
          </button>
        </div>

        <div
          className="col-12 col-md-6 col-lg-7 d-flex justify-content-center"
          id="backtest"
        >
          <Suspense fallback={<div></div>}>{<HomeImages2 />}</Suspense>
        </div>
      </div>
    </div>
  );
};
const Features = (props) => {
  let { dispatch } = useContext(globalContext);
  return (
    <div className="bg-white text-left overflow-hidden px-4 px-md-5 py-md-5">
      <h2 className="display-3 my-3 ">You need a software solution?</h2>
      <h3 className="h3 text-info mb-5">
        Leave the software to us, so you can focus on your success!
      </h3>

      <div className="justify-content-center row row-cols-1 row-cols-md-2">
        <div className="col">
          <div className="row row-cols-1 row-cols-sm-2">
            <div className="col">
              <ul className="list-unstyled ">
                <li>
                  <FontAwesomeIcon className="text-info" icon={faCheckCircle} />{" "}
                  Requirement Analysis
                </li>
                <li>
                  <FontAwesomeIcon className="text-info" icon={faCheckCircle} />{" "}
                  Design and Architecture
                </li>
                <li>
                  <FontAwesomeIcon className="text-info" icon={faCheckCircle} />{" "}
                  Software Development
                </li>
                <li>
                  <FontAwesomeIcon className="text-info" icon={faCheckCircle} />{" "}
                  Quality Assurance and Testing
                </li>
                <li>
                  <FontAwesomeIcon className="text-info" icon={faCheckCircle} />{" "}
                  Deployment and Implementation
                </li>
                <li>
                  <FontAwesomeIcon className="text-info" icon={faCheckCircle} />{" "}
                  Maintenance and Support
                </li>
              </ul>
            </div>
            <div className="col">
              <ul className="list-unstyled ">
                <li>
                  <FontAwesomeIcon className="text-info" icon={faCheckCircle} />{" "}
                  Mobile apps
                </li>
                <li>
                  <FontAwesomeIcon className="text-info" icon={faCheckCircle} />{" "}
                  Web development
                </li>
                <li>
                  <FontAwesomeIcon className="text-info" icon={faCheckCircle} />{" "}
                  Enterprise solutions
                </li>
                <li>
                  <FontAwesomeIcon className="text-info" icon={faCheckCircle} />{" "}
                  Custom Software Development
                </li>
                <li>
                  <FontAwesomeIcon className="text-info" icon={faCheckCircle} />{" "}
                  Integrations
                </li>
                <li>
                  <FontAwesomeIcon className="text-info" icon={faCheckCircle} />{" "}
                  Outsourcing
                </li>
              </ul>
            </div>
          </div>
          <div>
            <a
              className="btn btn-lg btn-info text-uppercase  mt-3"
              href="/"
              onClick={(e) => {
                e.preventDefault();
                document.activeElement.blur();
                showContactFormDialog(
                  dispatch,
                  "How can we help?",
                  "info@insentico.com"
                );
              }}
            >
              Contact us
            </a>
          </div>
        </div>
        <Suspense fallback={<div></div>}>{<HomeImages4 />}</Suspense>
      </div>
    </div>
  );
};

const Home = (props) => {

  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <>
      <div className="page-alt">
        <Meta
          title="Insentico Ltd. - Your Software Partner!"
          metaContent="Empowering businesses with cutting-edge software solutions to drive growth and stay ahead in the digital era."
        />
        <section id="crypto-trading-bot">
          <div
            className="bg-new-dark text-white intro row row-cols-1 row-cols-md-2 mx-0"
            id="easy-crypto-bot"
          >
            <div className="col p-5">
              <h1 className="major alt py-4 strech">
                <div className="logo d-lg-inline-block d-block">
                  <img src={logo} alt="Sofware development company" />
                </div>
                Insentico Ltd.
              </h1>
              <h2 className="text-info my-4 h4">Your software partner</h2>
            </div>
            <Suspense
              fallback={<div className="col d-none d-md-inline-block"></div>}
            >
              {<HomeImages1 />}
            </Suspense>
          </div>
        </section>
        <section
          id="crypto-trading-bot-details"
          className="bg-grad-dark-info pt-5"
        >
          <div className="container">
            <div className="row row-cols-lg-4 row-cols-md-2 row-cols-1 mx-auto text-light">
              <div
                className="col bg-transp"
                id="automate-crypto-trading-strategy"
              >
                <div className="card-body">
                  <div className="text-center h2">
                    <FontAwesomeIcon icon={faCode} />
                  </div>
                  <h2 className="card-title h5">Software Development</h2>
                  <h3 className="h6 font-weight-normal">
                    Code. Create. Conquer: Empowering Businesses through
                    Software Solutions!
                  </h3>
                </div>
              </div>
              <div
                className="col bg-transp"
                id="optimize-crypto-trading-strategy"
              >
                <div className="card-body">
                  <div className="text-center h2">
                    <FontAwesomeIcon icon={faWordpress} />
                  </div>
                  <h2 className="card-title h5">Web Development</h2>
                  <h3 className="h6 font-weight-normal">
                    Elevate Your Online Presence with Expert Web Development!
                  </h3>
                </div>
              </div>
              <div
                className="col bg-transp"
                id="template-crypto-trading-strategies"
              >
                <div className="card-body">
                  <div className="text-center h2">
                    <FontAwesomeIcon icon={faMobileAlt} />
                  </div>
                  <h2 className="card-title h5">
                    Mobile App
                    <br />
                    Android &amp; iOS
                  </h2>
                  <h3 className="h6 font-weight-normal">
                    Unlocking the Power of Mobile Innovation!
                  </h3>
                </div>
              </div>

              <div className="col bg-transp" id="safe-crypto-bot">
                <div className="card-body">
                  <div className="text-center h2">
                    <FontAwesomeIcon icon={faPeopleCarry} />
                  </div>
                  <h2 className="card-title h5">
                    Software Consulting and IT Solutions
                  </h2>
                  <h3 className="h6 font-weight-normal">
                    Navigating the Digital Landscape: Your Trusted Partner in
                    Software Consulting and IT Solutions!
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="bg-image bg-white position-relative">
          <svg
            className="lines"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
          >
            <path d="M0 0 L100 0 L0 100 Z" />
          </svg>
          <svg
            className="lines bottom"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
          >
            <path d="M100 0 L0 100 L100 100 Z" />
          </svg>
        </div>

        <section id="crypto-trading-bot-details" className="bg-grad-info-white">
          <div className="container row row-cols-lg-4 row-cols-md-2 row-cols-1 mx-auto text-dark">
            <div className="col bg-transp">
              <div className="card-body">
                <div className="text-center h2">
                  <FontAwesomeIcon icon={faSitemap} />
                </div>
                <h2 className="card-title h5">IT Outsourcing</h2>
                <h3 className="h6 font-weight-normal">
                  Relieve Your IT Burden, Trust Our Expertise!
                </h3>
              </div>
            </div>
            <div className="col bg-transp">
              <div className="card-body">
                <div className="text-center h2">
                  <FontAwesomeIcon icon={faUsers} />
                </div>
                <h2 className="card-title h5">Business Process Outsourcing</h2>
                Streamline Your Operations, Unlock Efficiency!
              </div>
            </div>
            <div className="col bg-transp">
              <div className="card-body">
                <div className="text-center h2">
                  <FontAwesomeIcon icon={faRunning} />
                </div>
                <h2 className="card-title h5">Knowledge Process Outsourcing</h2>
                Tap into Expertise, Amplify Your Success!
              </div>
            </div>
            <div className="col bg-transp">
              <div className="card-body">
                <div className="text-center h2">
                  <FontAwesomeIcon icon={faRainbow} />
                </div>
                <h2 className="card-title h5">Creative Outsourcing</h2>
                Unleash Creativity, Inspire Your Brand!
              </div>
            </div>
          </div>
        </section>

        <section id="crypto-trading-bot-features">
          <Features />
        </section>

        <section
          id="crypto-trading-bot-free-backtest"
          className="bg-new-dark intro text-light text-left position-relative"
        >
          <Backtest />
        </section>
        <section
          id="crypto-trading-bot-indicators"
          className="bg-new-dark intro text-light position-relative"
        >
          <Indicators />
          <svg
            className="lines bottom green"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
          >
            <path d="M0 0 L0 100 L100 100 Z" />
          </svg>
        </section>
        <section
          id="crypto-trading-bot-optimization"
          className="bg-light-green text-dark text-left overflow-hidden position-relative"
        >
          <Optimization />
        </section>

        <section id="crypto-trading-bot-faq" className="position-relative">
          <svg
            className="lines green"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
          >
            <path d="M0 0 L100 0 L100 100 Z" />
          </svg>

          <Questions
            classDiv="bg-white text-justify px-3 px-md-5 py-5"
            classH2="major my-5 py-4 h3 text-center"
          />
        </section>
      </div>
      <Footer />
    </>
  );
};

export default Home;
export { Questions };
