import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faAt,
} from "@fortawesome/free-solid-svg-icons";

const ExpandableCard = (props) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className="card bg-transparent border-0 mb-4 user-select-none">
      <h4
        className={`h6 card-header d-flex cursor-pointer ${props.classHeader}`}
        onClick={(e) => {
          e.preventDefault();
          document.activeElement.blur();
          setExpanded(!expanded);
        }}
      >
        <div className="flex-grow-1">{props.label}</div>
        {expanded ? (
          <FontAwesomeIcon icon={faAngleUp} />
        ) : (
          <FontAwesomeIcon icon={faAngleDown} className="blink" />
        )}
      </h4>
      <div className={expanded ? "d-block fade-in" : "d-none"}>
        <div className="card-body p-2">
          <div className="card-text text-justify">{props.toExpand}</div>
        </div>
      </div>
    </div>
  );
};
const Questions = (props) => {
  return (
    <div className={props.classDiv}>
      <h2 className={props.classH2}>Frequently Asked Questions</h2>
      <div className="row row-cols-1 row-cols-md-2">
        <div className="col">
          <ExpandableCard
            classHeader="bg-white"
            label="1. What is software outsourcing?"
            toExpand={
              <>
                Software outsourcing is the practice of hiring external
                companies or individuals to perform specific software
                development tasks or projects. Instead of building an in-house
                team, companies can outsource their software development needs
                to specialized firms or freelance professionals who have the
                expertise and resources to complete the required work
              </>
            }
          />
          <ExpandableCard
            classHeader="bg-white"
            label="2. What are the benefits of software outsourcing?"
            toExpand={
              <>
                Software outsourcing offers several benefits, including cost
                savings through more economical operations, access to
                specialized expertise from experienced professionals,
                scalability and flexibility in resource allocation, and the
                ability for companies to focus on their core competencies while
                leaving technical tasks to outsourcing vendors.
              </>
            }
          />
          <ExpandableCard
            classHeader="bg-white"
            label="3. Is software outsourcing safe?"
            toExpand={
              <>
                Software outsourcing can be made safe by carefully selecting
                reputable vendors, establishing clear contracts with robust
                security measures, maintaining open communication, and
                implementing quality assurance processes to mitigate risks.
              </>
            }
          />
          <ExpandableCard
            classHeader="bg-white"
            label="4. I have an idea for a mobile app. What to do?"
            toExpand={
              <>
                You can contact us via our contact form. Then we will schedule a
                meeting with you and talk about about your project, including
                your budget, timeline, and any specific features or
                functionalities you require. This will help us
                understand your needs and provide you with accurate guidance and
                cost estimates.
              </>
            }
          />
          <ExpandableCard
            classHeader="bg-white"
            label="5. I need to outsource my software project"
            toExpand={
              <>
                You can contact us via our contact form. Then we will schedule a
                meeting with you and talk about about your project, including
                your budget, timeline, and any specific features or
                functionalities you require. This will help us
                understand your needs and provide you with accurate guidance and
                cost estimates.
              </>
            }
          />
          <ExpandableCard
            classHeader="bg-white"
            label="6. I need a website"
            toExpand={
              <>
                You can contact us via our contact form. Then we will schedule a
                meeting with you and talk about about your project, including
                your budget, timeline, and any specific features or
                functionalities you require. This will help us
                understand your needs and provide you with accurate guidance and
                cost estimates.
              </>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Questions;
