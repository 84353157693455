import React, { useReducer } from "react";
import { globalContext, globalContextReducer, initialState } from "./Context.js";

const GlobalContextProvider = (props) => {
  let [state, dispatch] = useReducer(globalContextReducer, initialState);
  let value = { state, dispatch };
  
  let strategiesSort = localStorage.getItem("strategiesSort");
  if (strategiesSort) {
    state.strategiesSort = strategiesSort;
  }

  let commissionRate = localStorage.getItem("commissionRate");
  if (commissionRate) {
    try {
      state.commissionRate = Number.parseFloat(commissionRate);
    } catch (e) {}
  }
  return <globalContext.Provider value={value}>{props.children}</globalContext.Provider>;
};

let GlobalContextConsumer = globalContext.Consumer;

export { GlobalContextConsumer, GlobalContextProvider };
