import React from "react";

const Modal = (props) => {
  let buttonOkText = props.okText ? props.okText : "OK";
  let cancelBtn = props.cancelText && (
    <button
      type="button"
      className={`btn btn-sm ${props.dark ? "btn-dark" : "btn-secondary"} col-4 col-sm-2`}
      onClick={() => {
        let dialog = document.getElementById(props.id);
        dialog.classList.remove("show");
        dialog.setAttribute("aria-hidden", "true");
        dialog.setAttribute("style", "display: none");

        let mainPage = document.getElementById("mainPage");
        mainPage.classList.add("modal-open");
        mainPage.setAttribute("style", "opacity: 1");

        let body = document.body;
        body.classList.remove("modal-open");
      }}
    >
      {props.cancelText}
    </button>
  );
  let content = props.content && (
    <div className={"modal-body text-left " + (props.dark && props.large ? "px-md-5" : "")}>
      <div className={props.dark ? " bg-dark text-white py-4 intro rounded" : ""}>{props.content}</div>
    </div>
  );
  return (
    <div
      onKeyDown={(e) => {
        let keycode = e.keyCode ? e.keyCode : e.which;
        if (keycode === 13 || keycode === 27) {
          let dialog = document.getElementById(props.id);
          dialog.classList.remove("show");
          dialog.setAttribute("aria-hidden", "true");
          dialog.setAttribute("style", "display: none");

          let mainPage = document.getElementById("mainPage");
          mainPage.classList.add("modal-open");
          mainPage.setAttribute("style", "opacity: 1");

          let body = document.body;
          body.classList.remove("modal-open");
        }
      }}
      className="modal fade"
      id={props.id}
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div
        className={`modal-dialog ${props.large ? "modal-large" : ""}  ${props.center ? "modal-dialog-centered" : ""}`}
        role="document"
      >
        <div className="modal-content modal-content-slide-down">
          <div className="modal-header">
            {props.large ? (
              <h1 className="h4 modal-title text-center w-100 m-auto" id="modalLabel">
                {props.header}
              </h1>
            ) : (
              <h1 className="h4 modal-title" id="modalLabel">
                {props.header}
              </h1>
            )}

            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={() => {
                let dialog = document.getElementById(props.id);
                dialog.classList.remove("show");
                dialog.setAttribute("aria-hidden", "true");
                dialog.setAttribute("style", "display: none");

                let mainPage = document.getElementById("mainPage");
                mainPage.classList.add("modal-open");
                mainPage.setAttribute("style", "opacity: 1");

                let body = document.body;
                body.classList.remove("modal-open");
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          {content}
          <div className="modal-footer">
            <button
              type="button"
              className={`btn btn-sm ${props.dark ? "btn-dark" : "btn-secondary"}  col-4 col-sm-2 ${
                props.okDisabled ? "disabled" : ""
              }`}
              disabled={props.okDisabled ? "disabled" : ""}
              onClick={() => {
                let dialog = document.getElementById(props.id);
                dialog.classList.remove("show");
                dialog.setAttribute("aria-hidden", "true");
                dialog.setAttribute("style", "display: none");

                let mainPage = document.getElementById("mainPage");
                mainPage.classList.add("modal-open");
                mainPage.setAttribute("style", "opacity: 1");

                let body = document.body;
                body.classList.remove("modal-open");
                if (props.func !== undefined) {
                  props.func();
                }
              }}
            >
              {buttonOkText}
            </button>
            {cancelBtn}
          </div>
        </div>
      </div>
    </div>
  );
};

const ModalConfirm = (props) => {
  return (
    <Modal
      id={props.id}
      header={props.header}
      content={props.content}
      okText={props.okText ? props.okText : "YES"}
      okDisabled={props.okDisabled}
      cancelText="CANCEL"
      func={props.func}
      large={props.large}
      dark={props.dark}
      closeFn={props.closeFn}
    />
  );
};

export default Modal;
export { Modal, ModalConfirm };
